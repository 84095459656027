import React, { FC, ReactElement, useContext, useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import {Context} from "../..";
import useWindowDimensions from "../../core/hooks/useWindowDimensions";

import styles from "../../styles/BlurModal.module.scss";

interface ModalProps {
  visible: boolean
  title: ReactElement | string | null
  content: ReactElement | string
  footer?: ReactElement | string
  maxWidth?: string
  closable?: boolean
  centered?: boolean
  onClose: () => void
}

const WhiteModal: FC<ModalProps> = ({visible, title, content, footer, closable = true, centered = false, maxWidth = "550px", onClose}) => {

  const { loyaltyStore } = useContext(Context)

  const { width } = useWindowDimensions()

  const navigate = useNavigate()

  const [ hidingProceed, setHidingProceed ] = useState(false)

  const onKeydown = ({ key }: KeyboardEvent) => {
    switch (key) {
      case 'Escape':
        if (closable) {
          setHidingProceed(true)
          setTimeout(() => {
            onClose()
            setHidingProceed(false)
          }, 300)
        }
        break;
    }
  }

  const onCloseRef = () => {
    // const btm_modal = document.getElementById("btm_modal")
    // if (btm_modal != null) {
    //   return
    // }

    setHidingProceed(true)
    setTimeout(() => {
      onClose()
      setHidingProceed(false)
    }, 300);
  }

  const onCloseClick = (evt: any) => {
    if (loyaltyStore.isPayFailVisible) {
      loyaltyStore.setIsPayFailVisible(false)
      navigate("/store")
    }
    if (loyaltyStore.isPaySuccessVisible) {
      loyaltyStore.setIsPaySuccessVisible(false)
      navigate("/store")
    }
    console.log(evt)
    onCloseRef()
  }

  useEffect(() => {
    if (visible) {
      document.getElementsByTagName("html")[0].style.overflowY = "hidden"
      // document.getElementById("root")!.style.overflowY = "hidden"

      setTimeout(() => {
        let closings: string[] = ["close_blurmodal1", "close_blurmodal2"]

        closings.forEach(element => {
          const el = document.getElementById(element)
          if (el != null) {
            el.addEventListener("click", onCloseClick)
          }
        })
      }, 300)
    } else {
      document.getElementsByTagName("html")[0].style.overflowY = "unset"
      // document.getElementById("root")!.style.overflowY = "scroll"

      let closings: string[] = ["close_blurmodal1", "close_blurmodal2"]

        closings.forEach(element => {
          const el = document.getElementById(element)
          if (el != null) {
            el.removeEventListener("click", onCloseClick)
          }
        })
    }
  }, [visible])

  useEffect(() => {
    document.addEventListener('keydown', onKeydown)
    return () => {
      document.removeEventListener('keydown', onKeydown)
    }
  }, [])

  if (!visible) return null
  
  return (
    <div className={styles.modal + (centered ? " " + styles.centered : "")} style={{opacity: hidingProceed ? "0" : "1"}}>
      <div className={styles.modal_dialog} style={{maxWidth: maxWidth}} onClick={e => e.stopPropagation()}>
        <div className={styles.modal_header}>
          <div className="container" style={{position: "relative"}}>
            <div className="row">
              <div className="col-12">
                {title != null ? <h3 className={styles.modal_header__title}
                  style={{
                    textAlign: parseInt(maxWidth) > 420 ? "center" : "center"
                  }}>{title}</h3> : <></>}
                <span style={{display: closable ? "block" : "none"}} className={styles.modal_header__close} onClick={onCloseRef}>
                  {width > 768 ? <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L25 25" stroke="black" stroke-width="2"/>
                    <path d="M25 1L0.999998 25" stroke="black" stroke-width="2"/>
                  </svg> : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L15 15" stroke="black"/>
                    <path d="M15 1L0.999998 15" stroke="black"/>
                  </svg>}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.modal_body}
          style={{
            padding: parseInt(maxWidth) > 420 ? "0px 0px" : "0px"
          }}>
          <div className={styles.modal_body__content}>{content}</div>
        </div>
        {footer && <div className={styles.modal_footer}>{footer}</div>}
      </div>
    </div>
  )
}

export default WhiteModal