import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect } from "react"
import { Context } from "../../.."
import {ApiService} from "../../../core/api/api"
import { useFetching } from "../../../core/hooks/useFetching"

const StatsCounter: FC = () => {

  const { store, loyaltyStore } = useContext(Context)

  const [fetchData] = useFetching(async () => {
    const res = await ApiService.loyalty.stats.getBasicStats()

    console.log(res)
  })

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      
    </>
  )
}

export default observer(StatsCounter)