import { FC, useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import AppRouter from './components/AppRouter';
import { Context } from '.';
import useWindowDimensions from './core/hooks/useWindowDimensions';

import './styles/App.scss';
import GlobalFader from './components/GlobalFader';
import Header from './components/Header';
import Footer from './components/Footer';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useFetching } from './core/hooks/useFetching';
import { ApiService } from './core/api/api';
import { StorageKeys } from './core/utils/defaults';
import moment from 'moment';

import { IntlProvider } from 'react-intl';
import { Translation } from './core/models/Translate';
import { LOCALES } from './core/i18n/locales';
import { getCountryCode, getOS, isNullOrUndefined } from './core/utils/utils';
import ManagerButton from './components/manager/ManagerButton';
import { LoadingOutlined } from '@ant-design/icons';
import LoyaltyFooter from './pages/Loyalty/components/LoyaltyFooter';

import * as TelegramX from "telegram-webapps"
import StatsCounter from './pages/Loyalty/components/StatsCounter';
import {message} from 'antd';

const App: FC = () => {

  const { store } = useContext(Context)
  const location = useLocation()
  
  const [searchParams, setSearchParams] = useSearchParams()
  
  const [userName, setUserName] = useState("")
  const [userPhone, setUserPhone] = useState("")
  
  const { width, height } = useWindowDimensions()

  const [restoreSettings, isRestoreLoading, errorRestore] = useFetching(async () => {
    let dt: any = localStorage.getItem(StorageKeys.SETTING_DATE)
    if (dt != null) {
      dt = parseInt(dt)
      const now = moment().unix()
      if ((dt + 86400) < now) {
        fetchSettings()
        return
      }
    } else {
      localStorage.setItem(StorageKeys.SETTING_DATE, `${moment().unix()}`);
    }
    
    let tw: any = localStorage.getItem(StorageKeys.SETTING_TW)
    let tr: any = localStorage.getItem(StorageKeys.SETTING_TR)
    let fr: any = localStorage.getItem(StorageKeys.SETTING_FR)

    if (tw != null && tr != null && fr != null) {
      tw = JSON.parse(tw)
      tr = JSON.parse(tr)
      fr = JSON.parse(fr)

      store.setTechworks(tw.data)
      store.setFranchise(fr.data)

      let lst: any = {}, lst2: any[] = [], lst3: any[] = [];
      tr.data.forEach((lang: Translation) => {
        let ccode = getCountryCode(lang.nameEn);
        lst2.push({ name: lang.name, nameEn: lang.nameEn, code: ccode })

        lst[ccode] = {};
        lang.items.forEach((item) => {
          lst[ccode][item.name] = item.value;
        })

        lst3.push({...lang})
      });

      console.log(lst, lst2);

      const restoreLocale = localStorage.getItem(StorageKeys.LOCALE)
      if (restoreLocale != null) {
        store.setLocale(restoreLocale)
      } else {
        store.setLocale(lst2[0].code)
        localStorage.setItem(StorageKeys.LOCALE, lst2[0].code)
      }

      store.setTransAll(lst3)
      store.setTranslations(lst)
      store.setLocales(lst2)

      store.setIsSettingsInited(true)
    } else {
      fetchSettings()
    }
  })

  const [fetchSettings, isSetsLoading, errorSets] = useFetching(async () => {
    const res = await ApiService.core.getSettings()
    // let res: any = await fetch("/settings_abracadabra.json")
    // res = {
    //   data: await res.json()
    // }

    console.log(res)

    const tw = res.data.settings.find((v: any) => v.name == "techworks")
    const fr = res.data.settings.find((v: any) => v.name == "franchise")
    const tr = res.data.settings.find((v: any) => v.name == "translations")
    const hsl = res.data.settings.find((v: any) => v.name == "home_slider")

    const homeEvents = res.data.settings.find((v: any) => v.name == "home_events")
    const homeCollabs = res.data.settings.find((v: any) => v.name == "home_videos")
    const franEvents = res.data.settings.find((v: any) => v.name == "fran_events")
    const franCollabs = res.data.settings.find((v: any) => v.name == "fran_videos")
    const vacSlider = res.data.settings.find((v: any) => v.name == "vacancy_slider")
    const vacList = res.data.settings.find((v: any) => v.name == "vacancy_list")
    const maxmaConf = res.data.settings.find((v: any) => v.name == "maxma_config")
    
    store.setTechworks(tw.data)
    store.setFranchise(fr.data)
    store.setHomeSlides([...hsl.data])

    store.setHomeEvents(homeEvents.data)
    store.setHomeCollabs(homeCollabs.data)
    store.setFranEvents(franEvents.data)
    store.setFranCollabs(franCollabs.data)
    store.setVacancySlider(vacSlider.data)
    store.setVacancyList(vacList.data)
    store.setMaxmaConfig(maxmaConf.data)

    let lst: any = {}, lst2: any[] = [], lst3: any[] = [];
    tr.data.forEach((lang: Translation) => {
      let ccode = getCountryCode(lang.nameEn);
      lst2.push({ name: lang.name, nameEn: lang.nameEn, code: ccode })

      lst[ccode] = {};
      lang.items.forEach((item) => {
        if (ccode == "ru-RU") {
          lst[ccode][item.name] = item.value;
        } else {
          if (!isNullOrUndefined(item.value_en)) {
            lst[ccode][item.name] = item.value_en;
          } else {
            lst[ccode][item.name] = item.value;
          }
        }
      })

      lst3.push({...lang})
    });

    console.log(lst, lst2);

    const restoreLocale = localStorage.getItem(StorageKeys.LOCALE)
    if (restoreLocale != null) {
      store.setLocale(restoreLocale)
    } else {
      store.setLocale(lst2[0].code)
      localStorage.setItem(StorageKeys.LOCALE, lst2[0].code)
    }

    store.setTransAll(lst3)
    store.setTranslations(lst)
    store.setLocales(lst2)

    store.setIsSettingsInited(true)

    runTelegram()

    localStorage.setItem(StorageKeys.SETTING_TW, JSON.stringify(tw))
    localStorage.setItem(StorageKeys.SETTING_TR, JSON.stringify(tr))
    localStorage.setItem(StorageKeys.SETTING_FR, JSON.stringify(fr))
    localStorage.setItem(StorageKeys.SETTING_HSL, JSON.stringify(hsl.data))

    localStorage.setItem(StorageKeys.SETTING_HOME_EVENTS, JSON.stringify(homeEvents.data))
    localStorage.setItem(StorageKeys.SETTING_HOME_COLLABS, JSON.stringify(homeCollabs.data))
    localStorage.setItem(StorageKeys.SETTING_FRAN_EVENTS, JSON.stringify(franEvents.data))
    localStorage.setItem(StorageKeys.SETTING_FRAN_COLLABS, JSON.stringify(franCollabs.data))
    localStorage.setItem(StorageKeys.SETTING_VAC_SLIDER, JSON.stringify(vacSlider.data))
    localStorage.setItem(StorageKeys.SETTING_VAC_LIST, JSON.stringify(vacList.data))
    localStorage.setItem(StorageKeys.SETTING_MAXMA_CONF, JSON.stringify(maxmaConf.data))

    localStorage.setItem(StorageKeys.SETTING_DATE, `${moment().unix()}`)
  })

  const runTelegram = () => {
    Telegram.WebApp.ready()
    Telegram.WebApp.onEvent('themeChanged', setThemeClass)

    setThemeClass()

    Telegram.WebApp.setHeaderColor("#000000")
    Telegram.WebApp.setBackgroundColor("#000000")

    Telegram.WebApp.BackButton.onClick(() => {
      Telegram.WebApp.close()
    })

    setTimeout(() => {
      if (Telegram.WebApp.initData != null && Telegram.WebApp.initData != "") {
        // Todo: force auth with tg login
  
        const authed = store.checkAuthMaxma()
        if (authed == false) {
          signInWithTelegram()
        }
      }
    }, 2000)
  }

  const signInWithTelegram = async () => {
    // message.success(`${Telegram.WebApp.initDataUnsafe.user.username.toLowerCase()} !!!`)
    const res = await ApiService.loyalty.signInWithTelegram({
      login: Telegram.WebApp.initDataUnsafe.user.username.toLowerCase()
    })

    if (res.data.state == 0 || res.data.state == 2) {
      message.warning(res.data.message)
    } else if (res.data.state == 1) {
      message.success("Успешная авторизация!")
      store.applyMaxmaAuthWCode(res.data)
      store.setIsAuth(true)
      window.location.href = "/store"
    }
  }

  const setThemeClass = () => {
    document.documentElement.className = Telegram.WebApp.colorScheme;
  }

  const fetchReferal = () => {
    const ref = searchParams.get("r")

    console.log(`Referal ID: ${ref}`)

    if (ref != null) {
      localStorage.setItem(StorageKeys.REFERAL_ID, `${ref}`)
    }
  }

  useEffect(() => {
    if (store.isSettingsInited) {
      if (location.pathname == "/") {
        store.setIsFaderShown(true)
        store.setIsFaderVisible(true)

        setTimeout(() => {
          store.setIsFaderLogoVisible(true)
          setTimeout(() => {
            store.setAnimationToggle(true)
            store.setIsFaderShown(false)
            setTimeout(() => {
              store.setIsFaderVisible(false)
            }, 400)
          }, 1500)
        }, 300)
      } else {
        store.setAnimationToggle(true)
        store.setIsFaderShown(false)
        store.setIsFaderVisible(false)
      }
    }
  }, [store.isSettingsInited])

  useEffect(() => {
    if (width <= 768) {
      store.setIsMobile(true)
    } else {
      store.setIsMobile(false)
    }
  }, [width, height])

  useEffect(() => {
    if (width <= 768) {
      store.setIsMobile(true)
    } else {
      store.setIsMobile(false)
    }

    fetchSettings()

    fetchReferal()

    const onMouseUp = (event: any) => {
      console.log(event)
      let oldClass = document.body.classList.contains("red")
      const inter = setInterval(() => {
        const selection = window.getSelection()
        console.log(selection)
        if (selection && selection.type != "Range") {
          if (oldClass) {
            document.body.classList.remove("red")
          } else {
            document.body.classList.add("red")
          }
          
          clearInterval(inter)
        }
      }, 1000)
    }

    const pltfrm = getOS()
    if (pltfrm == "Windows") {
      store.setPlatform(pltfrm)
    }

    window.addEventListener("mouseup", onMouseUp)

    return () => {
      window.removeEventListener("mouseup", onMouseUp)
    }
  }, []);

  // if (
  //   location.pathname != "/" &&
  //   location.pathname != "/auth" &&
  //   !location.pathname.includes("/admin") &&
  //   !location.pathname.includes("/privacy")
  // ) {
  //   const isUser = localStorage.getItem(StorageKeys.USER)
  //   if (isUser != null) {
  //     const user = JSON.parse(isUser)
  //     if (
  //       user.id != 2
  //     ) {
  //       return (
  //         <>
  //           <div className="techworks">
  //             <div className="techworks_wrap">
  //               <div><i className="fas fa-screwdriver-wrench"></i></div>
  //               <h1>Технические работы</h1>
  //               <p>На данный момент на сайте проводятся плановые технические работы. Примерное время завершения - 21 июня, 18:00 (МСК).</p>
  //             </div>
  //           </div>
  //         </>
  //       )
  //     }
  //   } else {
  //     return (
  //       <>
  //         <div className="techworks">
  //           <div className="techworks_wrap">
  //             <div><i className="fas fa-screwdriver-wrench"></i></div>
  //             <h1>Технические работы</h1>
  //             <p>На данный момент на сайте проводятся плановые технические работы. Примерное время завершения - 21 июня, 18:00 (МСК).</p>
  //           </div>
  //         </div>
  //       </>
  //     )
  //   }
  // }

  return (
    store.isSettingsInited ? (location.pathname == "/barbershow" || location.pathname == "/barbershow2" ? <>
      <IntlProvider messages={store.translations[store.locale]} locale={store.locale} defaultLocale={LOCALES.RUSSIAN}><AppRouter /></IntlProvider>
    </> : <>
      <IntlProvider messages={store.translations[store.locale]} locale={store.locale} defaultLocale={LOCALES.RUSSIAN}>
        <GlobalFader />
        <StatsCounter />
        <Header
          animationToggle={store.animationToggle}
          onOpenMenu={() => {}}
          goToSection={() => {}} />
        <AppRouter />
        <LoyaltyFooter />
      </IntlProvider>
    </>) : <></>
  )
}

export default observer(App)
