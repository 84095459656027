import { observer } from "mobx-react-lite"
import { FC, useContext } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../..";
import { LOCALES } from "../../../core/i18n/locales";

import { FormattedMessage } from "react-intl"

import styles from "../../../styles/Footer.module.scss"
import { StorageKeys } from "../../../core/utils/defaults";
import { prevent } from "../../../core/utils/utils";
import useWindowDimensions from "../../../core/hooks/useWindowDimensions";
import { Collapse, CollapseProps } from "antd";
import BlurModal from "../../../components/modals/BlurModal";

const items: CollapseProps['items'] = [
  {
    key: '1',
    label: 'Зачем нужна подписка?',
    children: <p>Подписка активирует Ваш счет, куда будет приходить кешбэк. Всего есть 4 тарифа подписки: БАЗА, ПЛЮС, ПРО, ТОП. У каждого тарифа, помимо активации счета и начислений кешбэка, есть дополнительные преимущества, которые вы можете изучить в соответствующем разделе.</p>,
  },
  {
    key: '2',
    label: 'Где я могу тратить кешбэк?',
    children: <p>К вашему вниманию представлен магазин, в котором вы можете преобретать товары, оплачивая до 60% стоимости - кешбэком, а остаток - настоящими рублями.</p>,
  },
  {
    key: '3',
    label: 'Могу ли я оплатить стрижку бонусами?',
    children: <p>Накопленный кешбэк можно тратить только в магазине <b>TOPGUN+</b>. Их невозможно потратить на частичную или полную оплату стрижки в барбершопах TOPGUN.</p>,
  },
  {
    key: '4',
    label: 'Для кого эта программа?',
    children: <p>Поскольку, главным элементом лояльности является - кешбэк за оплаченные услуги в TOPGUN, клиентами программы могут быть только активные клиенты TOPGUN.</p>,
  },
  {
    key: '5',
    label: 'Куда обратиться с вопросами?',
    children: <p>При возникновении любых проблем связанных с работой сайта, просим незамедлительно обращаться к нам <a href="https://t.me/mike_topgun" target="_blank">напрямую в Telegram</a></p>,
  },
]

const LoyaltyFooter: FC = () => {

  const { store, loyaltyStore } = useContext(Context);
  const navigate = useNavigate()
  const location = useLocation()

  const { width } = useWindowDimensions()

  const navTo = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    let url = event.target.pathname || "/";

    if (event.target.tagName.toLowerCase() == "span") {
      url = event.target.parentElement.pathname;
    }

    if (url != location.pathname) {
      console.log(event, url);
  
      store.setIsFaderVisible(true);
      setTimeout(() => {
        store.setIsFaderShown(true);
        setTimeout(() => {
          navigate(url);
        }, 400);
      }, 100);
    }
  }

  const setupLocale = (locale: string) => {
    store.setLocale(locale)
    localStorage.setItem(StorageKeys.LOCALE, locale)
  }
  
  return (
    <>
      <div className={styles.section_footer} id="footer" style={{opacity: store.isAuth ? "1" : "1"}}>
        <div className="container">
          <div className="row">
            
            <div className="col-12">
              <div className={styles.copys}>
                <div className="row">
                  <div className="col-12 col-md-5">
                    <div className={styles.copys_links}>
                      {width > 768 ? <></> : <>
                        <a href="/rules" onClick={(ev) => {
                          prevent(ev)
                          loyaltyStore.setIsRulesVisible(true)
                        }}>Правила бонусной программы</a>
                      </>}
                      <a target="_blank" href="/support" onClick={(ev) => {
                        prevent(ev)
                        loyaltyStore.setIsSupportVisible(true)
                      }}>{store.locale == LOCALES.RUSSIAN ? "Поддержка" : "Support"}</a>
                      <a target="_blank" href="https://topgun.plus/uploads/personal.docx">{store.locale == LOCALES.RUSSIAN ? "Пользовательское соглашение" : "Terms of use"}</a>
                      {/* <a target="_blank" href="/privacy"><FormattedMessage id="footer_policy" /></a> */}
                      {/* <a target="_blank" href="/">Главная страница</a>
                      <a target="_blank" href="/franchise">Франшиза</a> */}
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <div className={styles.copys_links_r}>
                      <div className={styles.copys_links_r_w}>
                        {/* <div className={styles.langs_wrap}>
                          <div className={styles.langs}>
                            <div className={store.locale == LOCALES.RUSSIAN ? styles.active : ""} onClick={() => setupLocale(LOCALES.RUSSIAN)}>RU</div>
                            <div className={store.locale == LOCALES.ENGLISH ? styles.active : ""} onClick={() => setupLocale(LOCALES.ENGLISH)}>EN</div>
                            <div className={store.locale == LOCALES.FRANCE ? styles.active : ""} onClick={() => setupLocale(LOCALES.FRANCE)}>FR</div>
                          </div>
                        </div> */}
                        <div className={styles.txt}><FormattedMessage id="footer_copys_loyalty" /></div>
                        <div className={styles.txt}>© TOPGUN  2014 — 2024</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <BlurModal
        visible={loyaltyStore.isSupportVisible}
        title=""
        centered={true}
        onClose={() => loyaltyStore.setIsSupportVisible(false)}
        content={<>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1">
                <div className={styles.blurmodal_centered}>
                  <h3><b>Служба поддержки <span>клиентов</span></b></h3>
                  
                  <div className={styles.xcard} style={{marginTop: "20px"}}>
                    <div className="row">
                      <div className="col-12">
                        <h4>Часто задаваемые вопросы</h4>

                        <Collapse defaultActiveKey={[]} ghost items={items} />

                        <p style={{
                          textAlign: "center",
                          marginTop: "32px"
                        }}>
                          <a href="#" onClick={(ev) => {
                            prevent(ev)
                            loyaltyStore.setIsSupportVisible(false)
                            loyaltyStore.setIsRulesVisible(true)
                          }}>Правила сервиса</a>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </>} />
        
      <BlurModal
        visible={loyaltyStore.isRulesVisible}
        title=""
        centered={true}
        onClose={() => loyaltyStore.setIsRulesVisible(false)}
        content={<>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className={styles.blurmodal_centered}>
                  <h3><b>Правила <span>TOPGUN+</span></b></h3>

                  <div style={{transition: "all 0.4s ease"}}>
                    <div className="row">
                      <div className="col-12 col-md-10 offset-md-1">
                        <div className="row">
                          <div className="col-12 col-md-6 offset-md-3">
                            <div className={styles.xcard}>
                              <div className="row">
                                <div className="col-12 col-md-12">
                                  <p>После покупки подписки в системе накоплений <b>«TOPGUN+»</b> стоимость <b>всех будущих</b> оказанных услуг в <b>TOPGUN</b> буду возвращены на <b>накопительный счет рублями</b>, которые можно <b>потратить в магазине TOPGUN.</b></p>

                                  <div className="row">
                                    <div className="col-12">
                                      <ul>
                                        <li>География начислений - <b>любой TOPGUN РФ</b>, списание рублей - <b>только в интернет-магазине</b></li>
                                        <li><b>Начисления за услуги</b> в барбершопах <b>TOPGUN</b> происходят на <b>следующий день</b> после посещения</li>
                                        <li>При регистрации по пригласительной ссылке, <b>вы получите <strong className={styles.big}>5 000</strong> рублей</b>. <b>Для того чтобы сохранить их,</b> подключите подписку в течении <b>5ти дней</b> после регистрации.</li>
                                        <li><b>Для активации и действия счета</b> необходимо иметь активную <strong>подписку</strong></li>
                                      </ul>
                                    </div>
                                    <div className="col-12">
                                      <ul className={styles.mobx1}>
                                        <li>С помощью <b><strong>подписок</strong>,</b> вы можете <b>увеличивать</b> свою <b>выгоду</b> на товары магазина, а так же <b>уровень кешбэка за услуги</b></li>
                                        {/* <li>В случае отсутствия <b>повторного визита</b> в течении <b><strong>55ти дней</strong></b> от крайней даты посещения, <b>на 56ой</b> рубли сгорают</li> */}
                                        <li><b>Если <strong>не продлить/отменить</strong> подписку,</b> то все рубли <b>на счету <strong>сгорят</strong></b>.</li>
                                        <li><b>Если <strong>повысить уровень подписки сменой тарифа</strong>,</b> <b>бонусный счет будет <strong>заморожен</strong> на три месяца</b>. Его нельзя будет использовать для оплаты покупок в магазине. На понижение тарифа правило не распространяется.</li>
                                        <li><b><strong>Подписка, не подлежит возврату.</strong> Покупая подписку - вы соглашаетесь с правилами оферты, приобретаете услугу и сразу же получаете ее преимущества, часть которых имеет единоразовый характер.</b></li>
                                        {/* <li>Если клиент не продлил/отменил подпсику и не потратил баллы по подписке, то у него сгорят все бонусные рубли, полученные именно по подписке. У него останется только 10 000 б.р. (приветственные) и за все услуги, которые он оплатил в TOPGUN после активации.</li> */}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </>} />
    </>
  )
}

export default observer(LoyaltyFooter)